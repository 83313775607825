import React, { createContext, Component } from "react";

import translate from "../content/translate.json";
import languageJSON from "../content/languages.json";
import regionsJSON from "../content/regions.json";

export const LanguageContext = createContext("en");

class LanguageProvider extends Component {
  constructor(props) {
    super(props);

    const splitURL = window.location.pathname.split("/");
    this.translate = translate;
    this.langs = languageJSON[splitURL[1]] ?? languageJSON.default;
    let lang = this.langs.find((lang) => splitURL.includes(lang)) ?? "ar";
    let dir = languageJSON.rtlLangs.includes(lang) ? "rtl" : "";

    this.html = document.querySelector("html");
    this.html.setAttribute("lang", lang);
    localStorage.setItem("lang", lang);

    this.state = {
      lang,
      translate: translate[lang],
      dir
    };

    window.lang = lang;
    this.changeTitle(lang);
  }

  changeTitle(lang = "ar") {
    document.title = translate[lang].title;
  }

  toggleLang = (lang, push) => {
    if (window.lang === lang) return false;
    window.lang = lang;

    let newUrl = window.location.pathname;
    const langInUrl = this.langs.find((lang) => newUrl.includes(lang));
    if (langInUrl && "ar" === lang) {
      newUrl = newUrl.replace(`/${langInUrl}`, "");
    }
    if (!langInUrl) {
      newUrl = newUrl.split("/").reduce((acc, path, n) => {
        if (path === "") return acc;
        if (regionsJSON.regions.includes(path)) return `${acc}/${path}/${lang}`;
        return `${acc}/${path}`;
      }, "");
    }

    newUrl = newUrl.replace(`/${langInUrl}`, `/${lang}`);

    if (push) {
      push(newUrl);
    }
    
    let dir = languageJSON.rtlLangs.includes(lang) ? "rtl" : "";
    this.changeTitle(lang)
    const translate = this.translate[lang];
    this.setState({lang, translate, dir});
  };

  getTranslate = (key) => {
    let { translate } = this.state;
    if (key === undefined) return "";
    if (typeof key === "boolean") return key;
    const keys = key.split(".");
    const keysLength = keys.length;
    if (keysLength < 1) return key;
    for (let index = 0; index < keysLength; index++) {
      if (!translate?.[keys[index]]) return key;
      translate = translate[keys[index]];
    }
    return translate;
  };

  render() {
    return (
      <LanguageContext.Provider value={{
        ...this.state,
        toggleLang: this.toggleLang.bind(this),
        getTranslate: this.getTranslate.bind(this),
      }}>
        {this.props.children}
      </LanguageContext.Provider>
    )
  }
}

export default LanguageProvider;
